<template>
  <section class="counter-section" :style="{ 'background-image': `url('${background}')` }">
    <h5
      class="text-center text-white font-weight-lighter text-uppercase mb-3"
      v-if="diffInMs < 0"
    >We have been merried for</h5>
    <ul class="countdown d-flex justify-content-center">
      <li class>
        <span class="time">{{ days | two_digits }}</span>
        <span class="title">Days</span>
      </li>
      <li class>
        <span class="time">{{ hours | two_digits }}</span>
        <span class="title">Hours</span>
      </li>
      <li class>
        <span class="time">{{ minutes | two_digits }}</span>
        <span class="title">Minutes</span>
      </li>
      <li class>
        <span class="time">{{ seconds | two_digits }}</span>
        <span class="title">Seconds</span>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: ["background", "date"],
  mounted() {
    window.setInterval(() => {
      this.now = this.$moment();
    }, 1000);
  },
  data() {
    return {
      now: this.$moment()
    };
  },
  computed: {
    diffInMs() {
      return Math.trunc(this.$moment(this.date).diff(this.now) / 1000);
    },
    seconds() {
      const value = Math.trunc(this.diffInMs % 60);
      return this.diffInMs < 0 ? value * -1 : value;
    },
    minutes() {
      const value = Math.trunc(this.diffInMs / 60) % 60;
      return this.diffInMs < 0 ? value * -1 : value;
    },
    hours() {
      const value = Math.trunc(this.diffInMs / 60 / 60) % 24;
      return this.diffInMs < 0 ? value * -1 : value;
    },
    days() {
      const value = Math.trunc(this.diffInMs / 60 / 60 / 24);
      return this.diffInMs < 0 ? value * -1 : value;
    }
  }
};
</script>